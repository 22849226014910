import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_5/sub_1/slide1';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    allMdx(filter: {frontmatter: {language: {eq: "RU"}, title: {eq: "slide-5-1-1"}}}) {
      edges {
        node {
          frontmatter {
            author
            authorInfo
            title
          }
          body
        }
      }
    },
    file(relativePath: { eq: "chapter_5/sub_1/5-1-1-mobile.jpg"}) {
      childImageSharp {
          fixed(width: 1920) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
    }
  }
  `);
  return (
    <Template query={query} title="Часть I" />
  );
};


export default Slide;
