import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';

import Slide1 from 'src/slides/desktop/ru/chapter_5/sub_1/slide1';
import WrapperMobile from 'src/slides/mobile/ru/chapter_5/sub_1/wrapperMobile';

import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';


const allSlides = [<Slide1 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Эдгар Винсент Д'Абернон I Варшавская битва" description="Кто тот человек, который назвал Варшавскую битву 18-м решающим сражением в истории." lang="ru" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Эдгар Винсент Д'Абернон I Варшавская битва" description="Кто тот человек, который назвал Варшавскую битву 18-м решающим сражением в истории." lang="ru" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
